/* AUTO-GENERATED FILE - DO NOT EDIT */

/* Components */
/* project/vue-src/v-shop/components.js */
/* .blocks/v-shop-base-layout/vue-src/v-shop/components.js */
/* .blocks/v-shop-dynamic-home/vue-src/v-shop/components.js */
/* .blocks/v-shop/vue-src/v-shop/components.js */
/* .blocks/shop-payment-nave/vue-src/v-shop/components.js */
/* .blocks/shop-payment-modo/vue-src/v-shop/components.js */
/* .blocks/shop-payment-getnet/vue-src/v-shop/components.js */
/* .blocks/shop-payment-cash/vue-src/v-shop/components.js */
/* .blocks/shop-payment-gocuotas/vue-src/v-shop/components.js */
/* .blocks/cc-operator-first-data/vue-src/v-shop/components.js */
/* .blocks/shop-payment-credit-card/vue-src/v-shop/components.js */
/* .blocks/shop-payment-wiretransfer/vue-src/v-shop/components.js */
/* .blocks/shop-payment-mercadopago/vue-src/v-shop/components.js */

import Vue from 'vue'

const c = {}
const r = (a, b) => {
	c[a] = b
	Vue.component(a, b)
}
export const isComponent = (name) => name in c
export const getComponentsNames = () => Object.keys(c)

export const getComponents = () => ({ ...c })

r('Hook-CartItem-Nordenpet', require('@/v-shop/cart-drawer-hook/comp_Hook-CartItem-Nordenpet.vue').default)
r('VariantsSelector', require('@/v-shop/custom/comp_VariantsSelector.vue').default)
r('dpc-CategoriesBoard', require('@/v-shop/home/comp_dpc-CategoriesBoard.vue').default)
r('dpc-WallBrands', require('@/v-shop/home/comp_dpc-WallBrands.vue').default)
r('dpc-WallCategories', require('@/v-shop/home/comp_dpc-WallCategories.vue').default)
r('Hook-ProductCard-EventIcon', require('@/v-shop/product-card-hooks/comp_Hook-ProductCard-EventIcon.vue').default)
r('ActionBarMobile', require('@/v-shop/comp_ActionBarMobile.vue').default)
r('AllReviewsDialog', require('@/v-shop/comp_AllReviewsDialog.vue').default)
r('AppBar-Bottom-CategoriesMenu', require('@/v-shop/comp_AppBar-Bottom-CategoriesMenu.vue').default)
r('AppBar-Bottom-MenuItems', require('@/v-shop/comp_AppBar-Bottom-MenuItems.vue').default)
r('AppBar-Bottom', require('@/v-shop/comp_AppBar-Bottom.vue').default)
r('AppBar-Buttons', require('@/v-shop/comp_AppBar-Buttons.vue').default)
r('AppBar-Top', require('@/v-shop/comp_AppBar-Top.vue').default)
r('AppBar-TopMsg', require('@/v-shop/comp_AppBar-TopMsg.vue').default)
r('AppBar', require('@/v-shop/comp_AppBar.vue').default)
r('AppBarMobileSearch', require('@/v-shop/comp_AppBarMobileSearch.vue').default)
r('AppBarRound-Drawer', require('@/v-shop/comp_AppBarRound-Drawer.vue').default)
r('AppBarRound', require('@/v-shop/comp_AppBarRound.vue').default)
r('AppBarTransparent', require('@/v-shop/comp_AppBarTransparent.vue').default)
r('AutocompleteSearchField-ProductItem', require('@/v-shop/comp_AutocompleteSearchField-ProductItem.vue').default)
r('AutocompleteSearchField', require('@/v-shop/comp_AutocompleteSearchField.vue').default)
r('BrandsDialog', require('@/v-shop/comp_BrandsDialog.vue').default)
r('BrandSku', require('@/v-shop/comp_BrandSku.vue').default)
r('CategoriesMegaMenu-CategoriesList', require('@/v-shop/comp_CategoriesMegaMenu-CategoriesList.vue').default)
r('CategoriesMegaMenu-CategoryTitle', require('@/v-shop/comp_CategoriesMegaMenu-CategoryTitle.vue').default)
r('CategoriesMegaMenu-Image', require('@/v-shop/comp_CategoriesMegaMenu-Image.vue').default)
r('CategoriesMegaMenu-Overlay', require('@/v-shop/comp_CategoriesMegaMenu-Overlay.vue').default)
r('CategoriesMegaMenu', require('@/v-shop/comp_CategoriesMegaMenu.vue').default)
r('CategoriesMegaMenuHorizontal', require('@/v-shop/comp_CategoriesMegaMenuHorizontal.vue').default)
r('CollectionEventIcon', require('@/v-shop/comp_CollectionEventIcon.vue').default)
r('DiscountText', require('@/v-shop/comp_DiscountText.vue').default)
r('Footer-Bottom', require('@/v-shop/comp_Footer-Bottom.vue').default)
r('Footer-BrandsList', require('@/v-shop/comp_Footer-BrandsList.vue').default)
r('Footer-BusinessInfo', require('@/v-shop/comp_Footer-BusinessInfo.vue').default)
r('Footer-CategoriesList', require('@/v-shop/comp_Footer-CategoriesList.vue').default)
r('Footer-Certificates', require('@/v-shop/comp_Footer-Certificates.vue').default)
r('Footer-LinkList', require('@/v-shop/comp_Footer-LinkList.vue').default)
r('Footer-Newsletter', require('@/v-shop/comp_Footer-Newsletter.vue').default)
r('Footer-Top', require('@/v-shop/comp_Footer-Top.vue').default)
r('Footer', require('@/v-shop/comp_Footer.vue').default)
r('FooterContactList', require('@/v-shop/comp_FooterContactList.vue').default)
r('FooterLinkItem', require('@/v-shop/comp_FooterLinkItem.vue').default)
r('FooterLinkList', require('@/v-shop/comp_FooterLinkList.vue').default)
r('FooterSocialItem', require('@/v-shop/comp_FooterSocialItem.vue').default)
r('FooterSocialsList', require('@/v-shop/comp_FooterSocialsList.vue').default)
r('Hook-ProductPage-BaseTemplate', require('@/v-shop/comp_Hook-ProductPage-BaseTemplate.vue').default)
r('Hook-ProductPage-DescriptionAndAttrs', require('@/v-shop/comp_Hook-ProductPage-DescriptionAndAttrs.vue').default)
r('Hook-ProductPage-InfoDropdownImages', require('@/v-shop/comp_Hook-ProductPage-InfoDropdownImages.vue').default)
r('Hook-ProductPage-InfoTabs', require('@/v-shop/comp_Hook-ProductPage-InfoTabs.vue').default)
r('Hover', require('@/v-shop/comp_Hover.vue').default)
r('IconTextItem', require('@/v-shop/comp_IconTextItem.vue').default)
r('LimitedTimeOffer', require('@/v-shop/comp_LimitedTimeOffer.vue').default)
r('LimitedTimeOfferCountdown', require('@/v-shop/comp_LimitedTimeOfferCountdown.vue').default)
r('ListItemIcon', require('@/v-shop/comp_ListItemIcon.vue').default)
r('MobileMenuDrawer-Categories', require('@/v-shop/comp_MobileMenuDrawer-Categories.vue').default)
r('MobileMenuDrawer-CustomizableLinks', require('@/v-shop/comp_MobileMenuDrawer-CustomizableLinks.vue').default)
r('MobileMenuDrawer-MegaMenuCategories', require('@/v-shop/comp_MobileMenuDrawer-MegaMenuCategories.vue').default)
r('MobileMenuDrawer', require('@/v-shop/comp_MobileMenuDrawer.vue').default)
r('PaymentOptions', require('@/v-shop/comp_PaymentOptions.vue').default)
r('PriceLayout', require('@/v-shop/comp_PriceLayout.vue').default)
r('ProductActions', require('@/v-shop/comp_ProductActions.vue').default)
r('ProductActionsSmall', require('@/v-shop/comp_ProductActionsSmall.vue').default)
r('ProductDiscount', require('@/v-shop/comp_ProductDiscount.vue').default)
r('ProductImagesCarousel', require('@/v-shop/comp_ProductImagesCarousel.vue').default)
r('ProductImagesDialog', require('@/v-shop/comp_ProductImagesDialog.vue').default)
r('ProductImagesGrid', require('@/v-shop/comp_ProductImagesGrid.vue').default)
r('ProductImagesVertical', require('@/v-shop/comp_ProductImagesVertical.vue').default)
r('ProductInfoDescription', require('@/v-shop/comp_ProductInfoDescription.vue').default)
r('ProductPage-AdditionalPaymentInfo', require('@/v-shop/comp_ProductPage-AdditionalPaymentInfo.vue').default)
r('ProductPage-Attributes', require('@/v-shop/comp_ProductPage-Attributes.vue').default)
r('ProductPage-DeliveryCalc-Results', require('@/v-shop/comp_ProductPage-DeliveryCalc-Results.vue').default)
r('ProductPage-DeliveryCalc', require('@/v-shop/comp_ProductPage-DeliveryCalc.vue').default)
r('ProductPage-Description', require('@/v-shop/comp_ProductPage-Description.vue').default)
r('ProductPage-InfoDropdownImages', require('@/v-shop/comp_ProductPage-InfoDropdownImages.vue').default)
r('ProductPage-InfoTabs', require('@/v-shop/comp_ProductPage-InfoTabs.vue').default)
r('ProductPage-LimitedTimeOffer', require('@/v-shop/comp_ProductPage-LimitedTimeOffer.vue').default)
r('ProductPage-MainContainer', require('@/v-shop/comp_ProductPage-MainContainer.vue').default)
r('ProductPage-PaymentsModal', require('@/v-shop/comp_ProductPage-PaymentsModal.vue').default)
r('ProductPage-Reviews', require('@/v-shop/comp_ProductPage-Reviews.vue').default)
r('ProductReviews', require('@/v-shop/comp_ProductReviews.vue').default)
r('ProductShareBtn', require('@/v-shop/comp_ProductShareBtn.vue').default)
r('ProductSheetDefault', require('@/v-shop/comp_ProductSheetDefault.vue').default)
r('ProductSheetThreeColumns', require('@/v-shop/comp_ProductSheetThreeColumns.vue').default)
r('ProductStockAvailabilityMessage', require('@/v-shop/comp_ProductStockAvailabilityMessage.vue').default)
r('ProductStockQtyMessage', require('@/v-shop/comp_ProductStockQtyMessage.vue').default)
r('ShareProduct', require('@/v-shop/comp_ShareProduct.vue').default)
r('StockReminderForm', require('@/v-shop/comp_StockReminderForm.vue').default)
r('VariantSelector-SelectItem', require('@/v-shop/comp_VariantSelector-SelectItem.vue').default)
r('unused_comp_ProductPage-Attributes.vue', require('@/v-shop/unused_comp_ProductPage-Attributes.vue').default)
r('unused-comp_Footer-Top.vue', require('@/v-shop/unused-comp_Footer-Top.vue').default)
r('AppRouteLoadingBar', require('@/v-shop/app/comp_AppRouteLoadingBar.vue').default)
r('ShopApp', require('@/v-shop/app/comp_ShopApp.vue').default)
r('AppMessageDialog', require('@/v-shop/common/comp_AppMessageDialog.vue').default)
r('Breadcrumbs', require('@/v-shop/common/comp_Breadcrumbs.vue').default)
r('CardLayout', require('@/v-shop/common/comp_CardLayout.vue').default)
r('Carousel', require('@/v-shop/common/comp_Carousel.vue').default)
r('ClientSideOnly', require('@/v-shop/common/comp_ClientSideOnly.vue').default)
r('Container', require('@/v-shop/common/comp_Container.vue').default)
r('CookiesDisclaimer', require('@/v-shop/common/comp_CookiesDisclaimer.vue').default)
r('CountryPhoneCodeSelector', require('@/v-shop/common/comp_CountryPhoneCodeSelector.vue').default)
r('DeviceMatch', require('@/v-shop/common/comp_DeviceMatch.vue').default)
r('Hook-ShopApp-SmartPopup', require('@/v-shop/common/comp_Hook-ShopApp-SmartPopup.vue').default)
r('LoadingBar', require('@/v-shop/common/comp_LoadingBar.vue').default)
r('OfferCard', require('@/v-shop/common/comp_OfferCard.vue').default)
r('OfferDrawer', require('@/v-shop/common/comp_OfferDrawer.vue').default)
r('OfferDrawerButton', require('@/v-shop/common/comp_OfferDrawerButton.vue').default)
r('OfferItem', require('@/v-shop/common/comp_OfferItem.vue').default)
r('OrderTotalGoalProgress', require('@/v-shop/common/comp_OrderTotalGoalProgress.vue').default)
r('PriceText', require('@/v-shop/common/comp_PriceText.vue').default)
r('ProductCardPrice', require('@/v-shop/common/comp_ProductCardPrice.vue').default)
r('ProductCardRating', require('@/v-shop/common/comp_ProductCardRating.vue').default)
r('ProductCardText', require('@/v-shop/common/comp_ProductCardText.vue').default)
r('ProductDataSheet', require('@/v-shop/common/comp_ProductDataSheet.vue').default)
r('ProductPrevPrice', require('@/v-shop/common/comp_ProductPrevPrice.vue').default)
r('ProductPrice', require('@/v-shop/common/comp_ProductPrice.vue').default)
r('ProductsCarousel', require('@/v-shop/common/comp_ProductsCarousel.vue').default)
r('ProductsCarouselForFeaturedCard', require('@/v-shop/common/comp_ProductsCarouselForFeaturedCard.vue').default)
r('ProductsLayout', require('@/v-shop/common/comp_ProductsLayout.vue').default)
r('ProductWishlistToggler', require('@/v-shop/common/comp_ProductWishlistToggler.vue').default)
r('QtyInput', require('@/v-shop/common/comp_QtyInput.vue').default)
r('SkuChip', require('@/v-shop/common/comp_SkuChip.vue').default)
r('SmartPopup-Form', require('@/v-shop/common/comp_SmartPopup-Form.vue').default)
r('SmartPopupBottom', require('@/v-shop/common/comp_SmartPopupBottom.vue').default)
r('SmartPopupCenter', require('@/v-shop/common/comp_SmartPopupCenter.vue').default)
r('SmartPopupRight', require('@/v-shop/common/comp_SmartPopupRight.vue').default)
r('Title', require('@/v-shop/common/comp_Title.vue').default)
r('UrlMatch', require('@/v-shop/common/comp_UrlMatch.vue').default)
r('WhatsappBtn', require('@/v-shop/common/comp_WhatsappBtn.vue').default)
r('WhatsappChat', require('@/v-shop/common/comp_WhatsappChat.vue').default)
r('ForgotForm', require('@/v-shop/login/comp_ForgotForm.vue').default)
r('GoogleAuthButton', require('@/v-shop/login/comp_GoogleAuthButton.vue').default)
r('LoginDrawer', require('@/v-shop/login/comp_LoginDrawer.vue').default)
r('LoginForm', require('@/v-shop/login/comp_LoginForm.vue').default)
r('LoginForms', require('@/v-shop/login/comp_LoginForms.vue').default)
r('SignupForm', require('@/v-shop/login/comp_SignupForm.vue').default)
r('ThirdPartyLoginsButtons', require('@/v-shop/login/comp_ThirdPartyLoginsButtons.vue').default)
r('CartDrawer', require('@/v-shop/cart-drawer/comp_CartDrawer.vue').default)
r('CartItem', require('@/v-shop/cart-drawer/comp_CartItem.vue').default)
r('ProductCard-Design1', require('@/v-shop/product-card/comp_ProductCard-Design1.vue').default)
r('ProductCard-Design1Featured', require('@/v-shop/product-card/comp_ProductCard-Design1Featured.vue').default)
r('ProductCard-Design1Small', require('@/v-shop/product-card/comp_ProductCard-Design1Small.vue').default)
r('ProductCard-Design2', require('@/v-shop/product-card/comp_ProductCard-Design2.vue').default)
r('ProductCard-Design2Featured', require('@/v-shop/product-card/comp_ProductCard-Design2Featured.vue').default)
r('ProductCard-Design2Small', require('@/v-shop/product-card/comp_ProductCard-Design2Small.vue').default)
r('ProductCard-Design3', require('@/v-shop/product-card/comp_ProductCard-Design3.vue').default)
r('ProductCard-Design3Featured', require('@/v-shop/product-card/comp_ProductCard-Design3Featured.vue').default)
r('ProductCard-Design3Small', require('@/v-shop/product-card/comp_ProductCard-Design3Small.vue').default)
r('ProductCard-Design4', require('@/v-shop/product-card/comp_ProductCard-Design4.vue').default)
r('ProductCard-Design4Featured', require('@/v-shop/product-card/comp_ProductCard-Design4Featured.vue').default)
r('ProductCard-Design4Small', require('@/v-shop/product-card/comp_ProductCard-Design4Small.vue').default)
r('ProductCard-Design5', require('@/v-shop/product-card/comp_ProductCard-Design5.vue').default)
r('ProductCard-Design5Featured', require('@/v-shop/product-card/comp_ProductCard-Design5Featured.vue').default)
r('ProductCard-Design5Small', require('@/v-shop/product-card/comp_ProductCard-Design5Small.vue').default)
r('ProductCard-Design6', require('@/v-shop/product-card/comp_ProductCard-Design6.vue').default)
r('ProductCard-Design6Featured', require('@/v-shop/product-card/comp_ProductCard-Design6Featured.vue').default)
r('ProductCard-Design6Small', require('@/v-shop/product-card/comp_ProductCard-Design6Small.vue').default)
r('ProductCard-Design7', require('@/v-shop/product-card/comp_ProductCard-Design7.vue').default)
r('ProductCard-Design7Featured', require('@/v-shop/product-card/comp_ProductCard-Design7Featured.vue').default)
r('ProductCard-Design7Small', require('@/v-shop/product-card/comp_ProductCard-Design7Small.vue').default)
r('ProductCard-Design8', require('@/v-shop/product-card/comp_ProductCard-Design8.vue').default)
r('ProductCard-Design8Featured', require('@/v-shop/product-card/comp_ProductCard-Design8Featured.vue').default)
r('ProductCard-Design8Small', require('@/v-shop/product-card/comp_ProductCard-Design8Small.vue').default)
r('ProductCard-ForSmallCarousel', require('@/v-shop/product-card/comp_ProductCard-ForSmallCarousel.vue').default)
r('ProductCard-Sq', require('@/v-shop/product-card/comp_ProductCard-Sq.vue').default)
r('ProductCard', require('@/v-shop/product-card/comp_ProductCard.vue').default)
r('Media', require('@/media/comp_Media.vue').default)
r('RatioBox', require('@/media/comp_RatioBox.vue').default)
r('RatioIframe', require('@/media/comp_RatioIframe.vue').default)
r('RatioImage', require('@/media/comp_RatioImage.vue').default)
r('RatioPdf', require('@/media/comp_RatioPdf.vue').default)
r('RatioVideo', require('@/media/comp_RatioVideo.vue').default)
r('Button', require('@/vuetify-common/comp_Button.vue').default)
r('SafeLink', require('@/vuetify-common/comp_SafeLink.vue').default)
r('TextField', require('@/vuetify-common/comp_TextField.vue').default)
r('Validator', require('@/vuetify-common/comp_Validator.vue').default)
r('BannerItem', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_BannerItem.vue'))
r('dpc-Accordion', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Accordion.vue')
)
r('dpc-AccordionV2', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-AccordionV2.vue')
)
r('dpc-Banner1', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner1.vue'))
r('dpc-Banner2', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner2.vue'))
r('dpc-Banner3', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner3.vue'))
r('dpc-Banner4', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner4.vue'))
r('dpc-Banner5', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner5.vue'))
r('dpc-Banner6', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner6.vue'))
r('dpc-BannerUnified', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-BannerUnified.vue')
)
r('dpc-BrandsBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-BrandsBoard.vue')
)
r('dpc-CarouselMultiple', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiple.vue')
)
r('dpc-CarouselMultiplePro', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiplePro.vue')
)
r('dpc-DynamicBanner', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-DynamicBanner.vue')
)
r('dpc-DynamicDpc', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-DynamicDpc.vue')
)
r('dpc-EnhancedProductsCarousel', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-EnhancedProductsCarousel.vue')
)
r('dpc-GoogleReviews', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-GoogleReviews.vue')
)
r('dpc-Hotspot', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Hotspot.vue'))
r('dpc-HtmlInsert', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-HtmlInsert.vue')
)
r('dpc-ImagesCarousel', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImagesCarousel.vue')
)
r('dpc-ImagesSlider', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImagesSlider.vue')
)
r('dpc-ImagesSliderV2', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImagesSliderV2.vue')
)
r('dpc-ImageTextCta', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImageTextCta.vue')
)
r('dpc-ListCardWithIcon', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ListCardWithIcon.vue')
)
r('dpc-Newsletter', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Newsletter.vue')
)
r('dpc-OffersBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-OffersBoard.vue')
)
r('dpc-OffersPlusBanners', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-OffersPlusBanners.vue')
)
r('dpc-ProductsBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsBoard.vue')
)
r('dpc-ProductsBoardWithData', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsBoardWithData.vue')
)
r('dpc-ProductsCarouselUnified', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsCarouselUnified.vue')
)
r('dpc-ProductsListWithFilters', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsListWithFilters.vue')
)
r('dpc-Richtext', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Richtext.vue'))
r('dpc-SliderPlus2Banners', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-SliderPlus2Banners.vue')
)
r('dpc-SliderPlusFeatured', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-SliderPlusFeatured.vue')
)
r('dpc-Spacer', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Spacer.vue'))
r('dpc-VideoBackground', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-VideoBackground.vue')
)
r('dpc-WordpressPosts', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-WordpressPosts.vue')
)
r('DynamicPageItems', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_DynamicPageItems.vue')
)
r('HotspotItem', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_HotspotItem.vue'))
r('RecursiveHtmlJson', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_RecursiveHtmlJson.vue')
)
r('WordpressPostItem', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_WordpressPostItem.vue')
)
r('WordpressPostItemHorizontal', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_WordpressPostItemHorizontal.vue')
)
r('DeliveryOptionContent-PickupPoint', () =>
	import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_DeliveryOptionContent-PickupPoint.vue')
)
r('DeliveryOptionContent-Shipping', () =>
	import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_DeliveryOptionContent-Shipping.vue')
)
r('DeliveryOptionContent', () =>
	import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_DeliveryOptionContent.vue')
)
r('OrderSummary-Item', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary-Item.vue'))
r('OrderSummary-Totals', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary-Totals.vue'))
r('OrderSummary', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary.vue'))
r('AddressDialog', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_AddressDialog.vue'))
r('CheckoutCollapsedSummary', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutCollapsedSummary.vue')
)
r('CheckoutPaymentCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutPaymentCardContent.vue')
)
r('CheckoutStepBox', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutStepBox.vue')
)
r('CheckoutSummary', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutSummary.vue')
)
r('CountrySelector', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CountrySelector.vue')
)
r('IndeterminateProgressBtn', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_IndeterminateProgressBtn.vue')
)
r('MaskedTextField', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_MaskedTextField.vue')
)
r('CheckoutApp', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutApp.vue'))
r('CheckoutStep-confirm--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-confirm/comp_CheckoutStep-confirm--active.vue')
)
r('CheckoutStep-confirm--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-confirm/comp_CheckoutStep-confirm--done.vue')
)
r('CheckoutStep-confirm-Void--active', () =>
	import(
		/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-confirm/comp_CheckoutStep-confirm-Void--active.vue'
	)
)
r('CheckoutStep-contact--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-contact/comp_CheckoutStep-contact--active.vue')
)
r('CheckoutStep-contact--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-contact/comp_CheckoutStep-contact--done.vue')
)
r('CheckoutDeliveryOptionItem', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutDeliveryOptionItem.vue')
)
r('CheckoutDeliveryPickupPointsSelectorDialog', () =>
	import(
		/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutDeliveryPickupPointsSelectorDialog.vue'
	)
)
r('CheckoutDeliverySellerBox', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutDeliverySellerBox.vue')
)
r('CheckoutDeliverySellerOrderItemsBox', () =>
	import(
		/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutDeliverySellerOrderItemsBox.vue'
	)
)
r('CheckoutStep-delivery--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutStep-delivery--active.vue')
)
r('CheckoutStep-delivery--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-delivery/comp_CheckoutStep-delivery--done.vue')
)
r('CheckoutDiscountForm-couponCode', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-discount/comp_CheckoutDiscountForm-couponCode.vue')
)
r('CheckoutDiscountForm-giftCardCode', () =>
	import(
		/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-discount/comp_CheckoutDiscountForm-giftCardCode.vue'
	)
)
r('CheckoutStep-discount--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-discount/comp_CheckoutStep-discount--active.vue')
)
r('CheckoutStep-discount--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-discount/comp_CheckoutStep-discount--done.vue')
)
r('CheckoutStep-payment--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-payment/comp_CheckoutStep-payment--active.vue')
)
r('CheckoutStep-payment--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-payment/comp_CheckoutStep-payment--done.vue')
)
r('CheckoutStep-signin--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-signin/comp_CheckoutStep-signin--active.vue')
)
r('CheckoutStep-signin--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/step-signin/comp_CheckoutStep-signin--done.vue')
)
r('Autocomplete', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_Autocomplete.vue'))
r('DatePickerDialog', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_DatePickerDialog.vue'))
r('MagicButton', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_MagicButton.vue'))
r('Select', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_Select.vue'))
r('TextArea', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_TextArea.vue'))
r('ValidationMessage', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_ValidationMessage.vue'))
r('DefaultProducts', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_DefaultProducts.vue')
)
r('DescriptionExpandable', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_DescriptionExpandable.vue')
)
r('ShopFilters', () => import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFilters.vue'))
r('ShopFiltersGroup', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFiltersGroup.vue')
)
r('ShopFiltersRemovalChips', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFiltersRemovalChips.vue')
)
r('ShopSortSelect', () => import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopSortSelect.vue'))
r('UpsellProductCard', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/comp_UpsellProductCard.vue')
)
r('UserpanelLayout', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserpanelLayout.vue'))
r('UserOrderDetail-Contact', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-Contact.vue')
)
r('UserOrderDetail-Delivery', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-Delivery.vue')
)
r('UserOrderDetail-DigitalItems', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-DigitalItems.vue')
)
r('UserOrderDetail-Discount', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-Discount.vue')
)
r('UserOrderDetail-Payment', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-Payment.vue')
)
r('UserOrderDetail-PaymentInstructions', () =>
	import(
		/* webpackChunkName: "account" */ '@/v-shop/account/order-details/comp_UserOrderDetail-PaymentInstructions.vue'
	)
)
r('ChangePassword', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/comp_ChangePassword.vue')
)
r('ContactData', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/comp_ContactData.vue'))
r('ReviewCard', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/comp_ReviewCard.vue'))
r('UserData', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/comp_UserData.vue'))
r('UserReviewDialog', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/comp_UserReviewDialog.vue')
)
r('NotFoundPage', () => import(/* webpackChunkName: "notfoundpage" */ '@/v-shop/not-found-page/comp_NotFoundPage.vue'))
r('UserOrderDetail-PaymentInstructions-Nave', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-Nave.vue')
)
r('CheckoutStep-confirm-Nave--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-Nave--active.vue')
)
r('UserOrderDetail-PaymentInstructions-Modo', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-Modo.vue')
)
r('CheckoutStep-confirm-Modo--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-Modo--active.vue')
)
r('UserOrderDetail-PaymentInstructions-Getnet', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-Getnet.vue')
)
r('CheckoutStep-confirm-Getnet--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-Getnet--active.vue')
)
r('CheckoutStep-confirm-Cash--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-Cash--active.vue')
)
r('UserOrderDetail-PaymentInstructions-GoCuotas', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-GoCuotas.vue')
)
r('CheckoutStep-confirm-GoCuotas--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-GoCuotas--active.vue')
)
r('CreditCard-OperatorResolver-FirstData', () =>
	import(/* webpackChunkName: "credit-card" */ '@/v-shop/comp_CreditCard-OperatorResolver-FirstData.vue')
)
r('UserOrderDetail-Payment-CreditCard', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-Payment-CreditCard.vue')
)
r('CreditCardPlanSelector', () =>
	import(/* webpackChunkName: "credit-card" */ '@/v-shop/credit-card/comp_CreditCardPlanSelector.vue')
)
r('CheckoutStep-confirm-CreditCard--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-CreditCard--active.vue')
)
r('CheckoutStep-payment-CreditCard--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-payment-CreditCard--active.vue')
)
r('CheckoutStep-payment-CreditCard--done', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-payment-CreditCard--done.vue')
)
r('CreditCardFormInputs', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CreditCardFormInputs.vue')
)
r('CreditCardPlanBox', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CreditCardPlanBox.vue'))
r('PaymentsModal-CreditCard-Select', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product/comp_PaymentsModal-CreditCard-Select.vue')
)
r('ProductPage-PaymentsModal-CreditCard', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product/comp_ProductPage-PaymentsModal-CreditCard.vue')
)
r('UserOrderDetail-PaymentInstructions-WireTransfer', () =>
	import(
		/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-WireTransfer.vue'
	)
)
r('CheckoutStep-confirm-WireTransfer--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-WireTransfer--active.vue')
)
r('UserOrderDetail-PaymentInstructions-MercadoPago', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserOrderDetail-PaymentInstructions-MercadoPago.vue')
)
r('CheckoutStep-confirm-MercadoPago--active', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutStep-confirm-MercadoPago--active.vue')
)

export const views = {
	ProductPage: require('@/v-shop/view_ProductPage.vue').default,
	Home: require('@/v-shop/view_Home.vue').default,
	DynamicPage: () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/view_DynamicPage.vue'),
	Checkout: () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/view_Checkout.vue'),
	ShopList: () => import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/view_ShopList.vue'),
	ProductUpsells: () =>
		import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/view_ProductUpsells.vue'),
	UserFavorites: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/favorites/view_UserFavorites.vue'),
	UserOrderDetail: () =>
		import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/view_UserOrderDetail.vue'),
	UserOrders: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/order-details/view_UserOrders.vue'),
	UserAccount: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/view_UserAccount.vue'),
	UserReviews: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/user-account/view_UserReviews.vue'),
	RepentanceForm: () => import(/* webpackChunkName: "repentance" */ '@/v-shop/repentance/view_RepentanceForm.vue'),
}

/* Routes */
export const routesProviders = [
	require('./routes/routes_account.js').default,
	require('./routes/routes_catalog.js').default,
	require('./routes/routes_checkout.js').default,
	require('./routes/routes_dynamicPages.js').default,
	require('../../../v-shop-dynamic-home/vue-src/v-shop/routes_home.js').default,
]

/* Stores */
export const storesProviders = [require('./stores/store_cart.js').default, require('./stores/store_shop.js').default]

/* Plugins */
export const pluginsRunners = [
	require('../../../admin/vue-src/media/plugin_media.js').default,
	require('../../../site-analytics/vue-src/analytics/plugin_analytics.js').default,
	require('./plugins/plugin_app-component.js').default,
	require('./plugins/plugin_base-css-vars.js').default,
	require('./plugins/plugin_filters.js').default,
	require('./plugins/plugin_lang.js').default,
	require('./plugins/plugin_shop-analytics.js').default,
	require('./plugins/plugin_shop-api.js').default,
	require('./plugins/plugin_shop.js').default,
	require('./plugins/plugin_super-sticky.js').default,
	require('./plugins/plugin_user-wishlist.js').default,
	require('./plugins/plugin_vuetify.js').default,
	require('../../../../project/vue-src/v-shop/plugin_globals.js').default,
]

/* Styles */
require('./styles/styles___reset.scss')
require('./styles/styles__base.scss')
require('./styles/styles__fonts.scss')
require('./styles/styles__mdi-icons.scss')
require('./styles/styles_ssr-carousel.scss')
require('./styles/styles_v-checkbox.scss')
require('../../../../project/vue-src/v-shop/styles_fonts.css')
