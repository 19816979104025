<script>
import VariantsSelectorBase from '#/v-shop-base-layout/vue-src/v-shop/comp_VariantsSelector.vue'
export default {
	extends: VariantsSelectorBase,
}
</script>

<template>
	<div class="product-page__variants-selector base" v-if="!product.hasUniqueVariant">
		<slot v-bind="{ groups }">
			<div v-for="(group, n) of groups" :key="group.key" class="pb-4">
				<slot name="key" v-bind="{ key: group.key, group, groups }">
					<div :class="keyClass || 'pb-1 font-weight-bold text-uppercase'">
						{{ group.key }}
					</div>
				</slot>
				<div :class="valuesContainerClass || 'd-flex align-center flex-wrap'">
					<slot name="values" v-bind="{ group, groups }">
						<div v-for="(value, i) of getValues(n, group)" :key="i">
							<slot name="valueButton" v-bind="{ value, group, groups }">
								<Button
									@click="onInput(group, value)"
									:key="value.v"
									:color="$vars.cta"
									class="ma-1"
									:class="group.selected.v === value.v && 'cta'"
									:text="group.selected.v != value.v"
									small
								>
									{{ value.v }}
									<!-- <div
										v-if="group.displayAs === 'color' && value.displayValue"
										class="color-circle ml-2 my-1"
										:style="{ backgroundColor: value.displayValue }"
									></div>
									<Media
										class="ml-2 my-1"
										:src="value.displayMedia"
										width="24"
										v-if="group.displayAs === 'image' && value.displayMedia"
									/> -->
								</Button>
							</slot>
						</div>
					</slot>
				</div>
			</div>
		</slot>
	</div>
</template>

<style scoped>
/* .color-circle {
	height: 24px;
	width: 24px;
	border-radius: 100%;
	border: 1px dotted #999;
}

::v-deep .ratio-box__slot img {
	border-radius: 100%;
} */
</style>
